<template>
  <div>
    <button class="btn btn-filter btn-float mx-auto btn-filter-open">
      <i class="icon-equalizer"></i>
      Filtrar
    </button>

    <div class="btn-filter-drawer">
      <div class="btn-filter-drawer-header">
        <h3 class="font-weight-bold mb-0 my-auto">Filtros</h3>
        <button class="btn btn-lg btn-link btn-filter-close ml-auto p-0">
          <i class="icon-close"></i>
        </button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonFilter',
  async mounted() {
    /* eslint-disable no-undef */
    $('.btn-filter-close').on('click', async() => this.hide());
    $('.btn-filter-open').on('click', async() => this.show());
  },
  methods: {
    hide() {
      /* eslint-disable no-undef */
      $('.btn-filter-drawer').removeClass('show');
    },
    show() {
      /* eslint-disable no-undef */
      $('.btn-filter-drawer').addClass('show');
    },
  },
};
</script>

<style>
</style>
