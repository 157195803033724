<template>
  <ButtonFilter ref="buttonFilter">
    <form class="form-filter" @submit.prevent="getListBillsPayment">
      <h1 class="sub-title">Status</h1>
      <div class="form-group content-form">
        <div class="dropdown">
          <div
            class="form-control dropdown-select-like rounded-pill text-left text-lg-center"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-nowrap lbl-select">{{ statusSelectLabel }}</span>
          </div>
          <div
            class="dropdown-menu w-100"
            aria-labelledby="dropdownMenuButton"
            @click.stop
          >
            <div
              class="material-radio"
              v-for="(data, index) in status"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="statusradios"
                :id="data.value"
                :value="data.value"
                v-model="filter.status"
              />
              <label :for="data.value" class="form-check-label lbl-filter">{{
                data.label
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <h1 class="sub-title">Período</h1>
      <div class="form-group content-form">
        <div class="dropdown period-mobile">
          <div
            class="form-control dropdown-select-like rounded-pill text-left text-lg-center"
            id="dropdownMenuButtonPeriod"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="text-nowrap lbl-select">{{ periodSelectLabel }}</span>
          </div>
          <div
            class="dropdown-menu w-100"
            aria-labelledby="dropdownMenuButtonPeriod"
            @click.stop
          >
            <div
              class="material-radio"
              v-for="(data, index) in period"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                name="periodmobile"
                :id="data.value"
                :value="data.value"
                v-model="selectedPeriod"
              />
              <label :for="data.value" class="form-check-label lbl-filter">{{
                data.label
              }}</label>
            </div>
            <div class="report-period" v-if="selectedPeriod === 4">
              <div
                id="calendar-date-start"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="changeCalendarDateStart"
                class="form-control dropdown-select-like rounded-pill text-left text-lg-center text-nowrap"
              >
                <div
                  v-if="
                    selectedDate.start.getTime() !== selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">&nbsp;</span>
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                  <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
                  <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
                </div>
                <div
                  v-if="
                    selectedDate.start.getTime() === selectedDate.end.getTime()
                  "
                >
                  <span class="text-xs text-ghost">no dia&nbsp;</span>
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                </div>
              </div>
              <div
                class="dropdown-menu"
                aria-labelledby="calendar-date-start"
                @click.stop
                :class="{ show: calendarDateStart }"
              >
                <v-date-picker
                  mode="range"
                  v-model="selectedDate"
                  :show-day-popover="false"
                  :tint-color="datePickerColor"
                  show-caps
                  is-inline
                ></v-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown period-web">
          <div
            class="form-control dropdown-select-like rounded-pill text-left text-lg-center text-nowrap"
            id="dropdownMenuButton-c"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              v-if="selectedDate.start.getTime() !== selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">&nbsp;</span>
              <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
              <span class="text-xs text-ghost">&nbsp;-&nbsp;</span>
              <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
            </div>
            <div
              v-if="selectedDate.start.getTime() === selectedDate.end.getTime()"
            >
              <span class="text-xs text-ghost">no dia&nbsp;</span>
              <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
            </div>
          </div>
          <div
            class="dropdown-menu dropdown-menu-fixed calendar"
            aria-labelledby="dropdownMenuButton-c"
            @click.stop
          >
            <v-date-picker
              mode="range"
              v-model="selectedDate"
              :show-day-popover="false"
              :tint-color="datePickerColor"
              show-caps
              is-inline
              is-double-paned
            ></v-date-picker>
            <hr />
            <div class="footer-calendar">
              <div>
                <div class="dropdown">
                  <div
                    class="form-control dropdown-select-like rounded-pill text-left text-lg-center"
                    id="dropdownMenuButton-x"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="changeShowPeriod"
                  >
                    <span class="text-nowrap lbl-select">{{
                      periodSelectLabel
                    }}</span>
                  </div>
                  <div
                    class="dropdown-menu w-100 period"
                    :class="{ show: showPeriod }"
                    aria-labelledby="dropdownMenuButton-x"
                    @click.stop
                  >
                    <div
                      class="material-radio"
                      v-for="(data, index) in period"
                      :key="index"
                    >
                      <label class="form-check-label lbl-filter">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="status-period"
                          :value="data.value"
                          v-model="selectedPeriod"
                        />
                        {{ data.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-date">
                <div class="date-start border-date">
                  <span>{{ selectedDate.start | moment('DD/MM/YY') }}</span>
                </div>
                <div class="calendar-txt">
                  <p>a</p>
                </div>
                <div class="date-end border-date">
                  <span>{{ selectedDate.end | moment('DD/MM/YY') }}</span>
                </div>
              </div>
              <div class="content-btn">
                <WhiteLabel>
                  <button class="btn btn-applay" type="submit">Aplicar</button>
                </WhiteLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 class="sub-title">Linha digitável</h1>
      <div class="form-group content-form col-12 col-w-full col-lg-5 mb-4">
        <input
          type="text"
          class="form-control"
          v-model="filter.barCode"
          v-mask="barCodeMask"
          placeholder="Informe a linha digitável"
        />
      </div>
      <div class="form-group content-form">
        <WhiteLabel>
          <button
            type="submit"
            class="btn btn-send-filter btn-md btn-orange mr-auto"
            @click="apply()"
          >
            Aplicar filtros
          </button>
        </WhiteLabel>
      </div>
    </form>
  </ButtonFilter>
</template>

<script>
import ButtonFilter from '@/components/shared/ButtonFilter';
import WhiteLabel from '@/components/shared/WhiteLabel';
import BillsPaymentFilters from '@/models/BillsPaymentFilters';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import 'v-calendar/lib/v-calendar.min.css';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';

export default {
  name: 'AppFilterBillsPayment',

  components: {
    ButtonFilter,
    WhiteLabel,
  },

  props: {
    // eslint-disable-next-line vue/require-prop-types
    isMobile: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    lengthPage: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    indexPage: {
      required: true,
    },
  },

  data: () => ({
    showPeriod: false,
    calendarDateStart: false,
    status: [
      { value: 'all', label: 'Todos os status' },
      { value: 'paid', label: 'Confirmados' },
      { value: 'pending', label: 'Pendentes' },
      { value: 'canceled', label: 'Cancelados' },
      { value: 'reproved', label: 'Reprovados' },
    ],
    period: [
      { value: 1, label: 'Últimos 7 dias' },
      { value: 2, label: 'Últimos 30 dias' },
      { value: 3, label: 'Últimos 3 meses' },
      { value: 4, label: 'Informar período' },
    ],
    filter: new BillsPaymentFilters(),
    selectedDate: {
      start: new Date().addDays(-7),
      end: new Date(),
    },
    selectedPeriod: 1,
    moreBillsPayment: false,
  }),

  watch: {
    isMobile: function (newValue) {
      this.isMobile = newValue;
    },
    'filter.status': async function (newValue) {
      this.resetBillPaymentsList();

      await this.getListBillsPayment();
    },
    'selectedDate.start': function (newValue) {
      this.resetBillPaymentsList();

      this.filter.startDate = moment(newValue).format('YYYY-MM-DD');
    },
    'selectedDate.end': function (newValue) {
      this.resetBillPaymentsList();

      this.filter.endDate = moment(newValue).format('YYYY-MM-DD');
    },
    moreBillsPayment: async function (newValue) {
      await this.getListBillsPayment();
    },
  },

  async mounted() {
    this.filter.offset.limit = this.lengthPage;

    await this.getListBillsPayment();
  },

  computed: {
    statusSelectLabel: function () {
      let objetc = this.status.filter((s) => s.value === this.filter.status);
      if (objetc.length > 0) {
        return objetc[0].label;
      } else {
        return 'Selecione Status';
      }
    },
    periodSelectLabel: function () {
      let objetc = this.period.filter((s) => s.value === this.selectedPeriod);
      if (objetc.length > 0) {
        return objetc[0].label;
      } else {
        return 'Selecione Período';
      }
    },
    datePickerColor: function () {
      return window.localStorage.getItem('tertiaryColor') || '#FF7606';
    },
    barCodeMask() {
      if (this.filter.barCode == '')
        return '################################################################################';
      else if (this.filter.barCode.charAt(0) == '8')
        return '################################################';
      else return '###############################################';
    },
  },

  methods: {
    apply() {
      this.$refs.buttonFilter.hide();
    },

    async getListBillsPayment() {
      this.$bus.$emit('spinner-run');

      const api = new PaymentAccountsApi();
      var payments = await api.getListPayments(this.filter);

      if (payments.length === 0) {
        this.$emit(
          'captureNotFound',
          this.filter.startDate,
          this.filter.endDate
        );
      }

      if (this.isMobile) {
        if (this.filter.offset.index === 0) {
          this.billsPayment = payments;
        } else {
          this.billsPayment.payments = this.billsPayment.payments.concat(
            payments.payments
          );
        }
      } else {
        if (this.filter.offset.index === 0) {
          this.billsPayment = payments;
        } else {
          this.billsPayment.payments = this.billsPayment.payments.concat(
            payments.payments
          );
        }
      }

      this.$emit('captureBillsPayment', this.billsPayment);
      this.$bus.$emit('spinner-stop');
    },
    changeShowPeriod: function (event) {
      this.showPeriod = !this.showPeriod;
    },
    changeCalendarDateStart: function (event) {
      this.calendarDateStart = !this.calendarDateStart;
    },
    resetBillPaymentsList() {
      this.billsPayment = null;
      this.filter.offset.index = 0;
      this.$emit('captureBillsPayment', this.billsPayment);
    },
  },
};
</script>
