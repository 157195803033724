<template>
  <div>
    <div class="down">
      <div class="not-found">
        <div>
          <h1 class="font-16">
            Pagamento de contas entre
            <b
              >{{ rangeDate.start | moment('DD/MM/YY') }} -
              {{ rangeDate.end | moment('DD/MM/YY') }}</b
            >
          </h1>
        </div>
        <div>
          <h1 class="font-i">
            <i class="icon transfer"></i>
          </h1>
        </div>
        <div>
          <h1 class="font-18">Nenhum pagamento encontrado</h1>
        </div>
        <div>
          <h1 class="font-14">
            Edite os filtros que você usou para encontrar mais resultados.
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNotFoundBillsPayment',
  props: {
    rangeDate: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.down {
  background: #fafafa;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .font-16 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #5e5e5e;
    text-align: center;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
  }

  .font-18 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #7d8287;
  }

  .font-14 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 146.48%;
    text-align: center;
  }

  .not-found {
    .font-i {
      text-align: center;
      margin-top: 3.5rem;
      .transfer {
        background-color: #d1d1d1;
      }
    }
  }
}
</style>
