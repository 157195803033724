export default class BillsPayment {
    paymentId = ''
    createdAt = new Date()
    discount = 0.0
    penalty = 0.0
    recipientDocument = ''
    recipientName = ''
    scheduleFor = new Date()
    status = ''
    value = 0.0
    valuePaid = 0.0
}
