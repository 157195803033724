<template>
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-12 col-w-full">
        <div
          class="card card-action py-4 mb-3"
          v-for="payment in billsPayment"
          :key="payment.paymentId"
          :class="[
            payment.status === 'paid' || payment.status === 'chargeback'
              ? 'card-status-success'
              : payment.status === 'reproved'
              ? 'card-status-reproved'
              : payment.status === 'canceled'
              ? 'card-status-canceled'
              : payment.status === 'pending'
              ? 'card-status-pending'
              : '',
          ]"
        >
          <div class="row m-0">
            <div class="col col-xl-1 order-1 d-flex">
              <span class="text-secondary text-md my-auto ml-3">
                {{ payment.paymentId }}
              </span>
            </div>
            <div class="col col-xl-5 order-1 d-flex">
              <span class="text-secondary text-md my-auto">
                {{ payment.recipientDocument }} - {{ payment.recipientName }}
              </span>
            </div>
            <div class="col col-xl-3 order-1 d-flex">
              <span
                v-if="payment.status == 'paid'"
                class="text-success text-md my-auto"
              >
                Pago em {{ payment.paymentDate | moment('DD/MM/YYYY') }}
              </span>
              <span
                v-else-if="payment.status == 'pending'"
                class="text-pending text-md my-auto"
              >
                Agendado para {{ payment.scheduleFor | moment('DD/MM/YYYY') }}
              </span>
              <span
                v-else-if="payment.status == 'canceled'"
                class="text-canceled text-md my-auto"
              >
                Cancelado em {{ payment.canceledDate | moment('DD/MM/YYYY') }}
              </span>
              <span
                v-else-if="payment.status == 'reproved'"
                class="text-reproved text-md my-auto"
              >
                Reprovado em {{ payment.rejectedDate | moment('DD/MM/YYYY') }}
              </span>
              <span v-else-if="payment.status == 'chargeback'" class="text-success text-md my-auto">
                Estornado em {{ payment.chargebackAt | moment('DD/MM/YYYY') }}
              </span>
              <span v-else class="text-secondary text-md my-auto">
                {{ payment.createdAt | moment('DD/MM/YYYY') }}
              </span>
            </div>
            <div class="col col-xl-3 order-last d-flex">
              <div class="d-flex">
                <span class="text-secondary text-md my-auto">
                  {{
                    payment.value
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </span>
              </div>
              <div class="dropdown text-center mx-auto justify-content-end">
                <WhiteLabel>
                  <button
                    type="button"
                    class="btn btn-outline-orange btn-pill dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Ações
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        class="dropdown-item"
                        :to="{
                          name: 'details-bills-payment',
                          params: {
                            payment: payment,
                            paymentId: payment.paymentId,
                          },
                        }"
                        >Visualizar</router-link
                      >
                    </li>
                    <li v-if="payment.status === 'pending'">
                      <a
                        type="button"
                        class="dropdown-item"
                        @click="cancel(payment.paymentId)"
                      >
                        Cancelar
                      </a>
                    </li>
                  </ul>
                </WhiteLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import swal from 'sweetalert2';
import Alert from '@/models/Alert';
import TwoFactorAuthError from '@/errors/TwoFactorAuthError';
import BillsPaymentError from '@/errors/BillsPaymentError';

export default {
  name: 'AppContentBillsPayment',
  components: {
    WhiteLabel,
  },
  data() {
    return {
      token: null,
      twoFactorAuthTaxDocument: null,
      twoFactorAuthPassword: null,
      twoFactorDocumentRegex: new RegExp('^[0-9]+$'),
    };
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    billsPayment: {
      required: true,
    },
  },
  methods: {
    async cancel(id) {
      const api = new PaymentAccountsApi();
      const accountApi = new AccountApi();

      swal({
        title:
          'Para cancelar um pagamento é necessário informar os quatro primeiros dígitos do seu CPF e sua senha',
        html:
          '<input type="text" maxlength="4" id="swal-input-taxDocument" class="form-control text-center" placeholder="Informe os quatro primeiros dígitos do seu CPF"> <br />' +
          '<input type="password" id="swal-input-password" class="form-control text-center" placeholder="Informe sua senha"> <br />',
        confirmButtonText: 'Salvar alterações',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (twoFactorAuthentication) => {
          this.token = null;
          this.twoFactorAuthTaxDocument = document.getElementById(
            'swal-input-taxDocument'
          ).value;
          this.twoFactorAuthPassword = document.getElementById(
            'swal-input-password'
          ).value;

          var match = this.twoFactorDocumentRegex.test(
            this.twoFactorAuthTaxDocument
          );

          if (
            this.twoFactorAuthTaxDocument == '' ||
            this.twoFactorAuthTaxDocument == ''
          ) {
            swal.showValidationError('Ambos os campos são obrigatórios!');

            return;
          }

          if (!match) {
            swal.showValidationError('Digite apenas números!');
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          var response = await accountApi.do2FA(
            this.twoFactorAuthTaxDocument,
            this.twoFactorAuthPassword,
            null
          );

          if (response.data.error) {
            const modelError = new TwoFactorAuthError(response.data);
            this.$alert.error(
              'Ops, erro durante autenticação',
              modelError.getMessage()
            );
          } else {
            this.token = response.data.token;
          }

          this.$bus.$emit('spinner-run');
          response = await api.cancelBillsPayment(id, this.token);

          if (response.status !== 200) {
            var alert = new Alert();
            alert.title = 'Ops';
            alert.confirmButtonText = 'Sair';
            const modelError = new BillsPaymentError(response.data);
            const error = modelError.getMessage();

            alert.error = error;

            this.$bus.$emit('spinner-stop');
            this.$bus.$emit('alert-error', alert);
          } else {
            this.$bus.$emit('spinner-stop');
            this.isDisabled = true;
            this.$alert.info('Pronto', 'Pagamento cancelado com sucesso!');
          }
        }
      });
    },
  },
};
</script>
