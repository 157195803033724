import CustomApiError from './CustomError';

export default class BillsPaymentError extends CustomApiError {
  toDictionary() {
    return {
      PAYMENTS_NOT_FOUND: 'Pagamento não encontrado.',
      FAILED_TO_REQUEST_CANCELLATION: 'Tente mais tarde',
      FAILED_TO_VALIDATE_BAR_CODE: 'Código de barras inválido',
    };
  }
}
