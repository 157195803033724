import CustomApiError from './CustomError';

export default class SendBillPaymentError extends CustomApiError {
  toDictionary() {
    return {
      FAILED_TO_SEND_PAYMENTS: 'Falha ao enviar o pagamento',
      AMOUNT_NOT_AVAILABLE: 'Saldo insuficiente',
      BALANCE_BLOCKED: 'Saldo bloqueado',
      BILL_ALREADY_SENT: 'Você já pagou esta conta',
      PAYMENT_ACCOUNT_NOT_FOUND: 'Conta não encontrada',
      PARTIAL_PAYMENT_NOT_ALLOWED: 'Pagamento parcial não permitido',
      PARTIAL_AMOUNT_LOWER_OR_BIGGER_THAN_ALLOWED:
        'Pagamento parcial menor ou maior que o permitido',
      UNABLE_TO_PAY_TODAY: 'Pagamento não permitido neste dia',
      BILL_ALREADY_EXPIRED: 'Data limite de pagamento inválida',
    };
  }
}
