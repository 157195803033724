<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card mt-5">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Pagamento de Conta</h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <router-link :to="{ name: 'bills-payment' }">
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />
          <form action="#" @submit.prevent="save()">
            <div class="card-content mb-5">
              <div class="form-row mb-4">
                <BillPaymentValidate @paymentMode="setPaymentMode"
                                     @billInfo="setBillInfo"
                                     v-if="billInfo == null"/>
              </div>
              <div v-if="billInfo != null">
                <div class="form-row">
                  <div class="col-sm-12">
                    <WhiteLabel>
                      <h4 class="font-weight-light blue">
                        Informações da conta
                      </h4>
                    </WhiteLabel>
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                    <label>Beneficiário</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="billInfo.recipient"
                      disabled
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-3 mb-4">
                    <label>Valor</label>
                    <currency-input
                      v-model="billInfo.amount"
                      class="form-control"
                      v-bind="currencyOptions"
                      disabled
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-3 mb-4">
                    <label>Data de vencimento</label>
                    <input
                      v-if="billInfo.dueDate != null"
                      class="form-control"
                      type="text"
                      v-model="billInfo.dueDate"
                      disabled
                    />
                    <input
                      v-else
                      class="form-control"
                      type="text"
                      value="Não informado"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-row" v-if="billInfo.allowPartialPayment">
                  <div class="form-group col-12">
                    <WhiteLabel>
                      <h4 class="font-weight-light blue">
                        Regras de recebimento
                      </h4>
                    </WhiteLabel>
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-4 mb-4">
                    <label>Valor de pagamento mínimo</label>
                    <currency-input
                      v-if="billInfo.allowPartialPayment"
                      v-model="billInfo.receiptRules.minPaymentValue"
                      class="form-control"
                      v-bind="currencyOptions"
                      disabled
                    />
                    <input
                      v-else
                      class="form-control"
                      type="text"
                      value="-"
                      disabled
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-4 mb-4">
                    <label>Valor de pagamento máximo</label>
                    <currency-input
                      v-if="billInfo.allowPartialPayment"
                      v-model="billInfo.receiptRules.maxPaymentValue"
                      class="form-control"
                      v-bind="currencyOptions"
                      disabled
                    />
                    <input
                      v-else
                      class="form-control"
                      type="text"
                      value="-"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <WhiteLabel>
                      <h4 class="font-weight-light blue">Juros e descontos</h4>
                    </WhiteLabel>
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-2 mb-4">
                    <label>Juros</label>
                    <currency-input
                      v-model="billInfo.computedBillValues.fineValueCalculated"
                      class="form-control"
                      v-bind="currencyOptions"
                      disabled
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-2 mb-4">
                    <label>Descontos</label>
                    <currency-input
                      v-model="
                        billInfo.computedBillValues.discountValueCalculated
                      "
                      class="form-control"
                      v-bind="currencyOptions"
                      disabled
                    />
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-2 mb-4">
                    <label>Valor atualizado</label>
                    <currency-input
                      v-model="billInfo.totalUpdated"
                      class="form-control"
                      v-bind="currencyOptions"
                      disabled
                    />
                  </div>
                </div>
                <div class="form-row mt-3" v-if="paymentMode=='darf'">
                  <div class="form-group col-12">
                    <WhiteLabel>
                      <h4 class="font-weight-light blue">DARF</h4>
                    </WhiteLabel>
                  </div>
                  <div class="form-group col-12 col-lg-4 col-w-full mb-4">
                    <label>Código da receita *</label>
                    <input
                      class="form-control"
                      type="text"
                      maxlength="4"
                      v-model="darf.federalRevenueCode"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.darf.federalRevenueCode.required &&
                        $v.darf.federalRevenueCode.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                    <small
                      class="text-danger"
                      v-if="
                        !$v.darf.federalRevenueCode.onlyNumbers ||
                        !$v.darf.federalRevenueCode.exactLength &&
                        $v.darf.federalRevenueCode.$dirty
                      "
                    >
                      Requer 4 números
                    </small>
                  </div>
                  <div class="form-group col-12 col-lg-4 col-w-full mb-4">
                    <label>Nome do arrecadador *</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="darf.federalRevenueName"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.darf.federalRevenueName.required &&
                        $v.darf.federalRevenueName.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                  </div>
                  <div class="form-group col-12 col-lg-4 col-w-full mb-4">
                    <label>Data de apuração *</label>
                    <datepicker
                      v-model="darf.assessmentPeriod"
                      input-class="form-control text-center scheduledField"
                      format="dd/MM/yyyy"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.darf.assessmentPeriod.required &&
                        $v.darf.assessmentPeriod.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                  </div>
                </div>
                <div class="form-row mb-3" v-if="paymentMode=='fgts'">
                  <div class="form-group col-12">
                    <WhiteLabel>
                      <h4 class="font-weight-light blue">FGTS</h4>
                    </WhiteLabel>
                  </div>
                  <div class="form-group col-12 col-lg-4 col-w-full mb-4">
                    <label>Código de recolhimento *</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="fgts.collectionCode"
                      v-if="paymentMode=='fgts'"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.fgts.collectionCode.required &&
                        $v.fgts.collectionCode.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <WhiteLabel>
                      <h4 class="font-weight-light blue">Pagamento</h4>
                    </WhiteLabel>
                  </div>
                  <div
                    class="form-group col-12 col-w-full mb-4"
                    :class="
                      billInfo.allowPartialPayment === true
                        ? 'col-lg-6'
                        : 'col-lg-9'
                    "
                  >
                    <label>Descrição *</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="billPayment.description"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.billPayment.description.required &&
                        $v.billPayment.scheduledFor.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                  </div>
                  <div class="form-group col-12 col-w-full col-lg-3 mb-4">
                    <label>Data de pagamento *</label>
                    <datepicker
                      v-model="billPayment.scheduledFor"
                      input-class="form-control text-center scheduledField"
                      format="dd/MM/yyyy"
                      placeholder="00/00/0000"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.billPayment.scheduledFor.required &&
                        $v.billPayment.scheduledFor.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                    <small
                      class="text-danger"
                      v-if="!scheduledForDateGreaterThanOrEqualToToday"
                    >
                      A data de agendamento não pode ser menor que hoje
                    </small>
                  </div>
                  <div
                    v-if="billInfo.allowPartialPayment"
                    class="form-group col-12 col-w-full col-lg-3 mb-4"
                  >
                    <label>Pagamento parcial</label>
                    <currency-input
                      v-model="billPayment.partialAmount"
                      class="form-control"
                      v-bind="currencyOptions"
                      maxlength="21"
                      :disabled="!billInfo.allowPartialPayment"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.billPayment.partialAmount.required &&
                        $v.billPayment.partialAmount.$dirty
                      "
                    >
                      Campo obrigatório
                    </small>
                    <small
                      class="text-danger"
                      v-if="
                        !$v.billPayment.partialAmount.minValue &&
                        $v.billPayment.partialAmount.$dirty
                      "
                    >
                      O valor mínimo é R$ 0,01
                    </small>
                    <small
                      class="text-danger"
                      v-if="
                        !$v.billPayment.partialAmount.maxValue &&
                        $v.billPayment.partialAmount.$dirty
                      "
                    >
                      O valor máximo é R$ 9.999.999,99
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <WhiteLabel class="mb-4 mx-auto">
                <button
                  type="submit"
                  class="btn btn-md btn-orange"
                  @click.prevent="validateBillInfo"
                  v-if="billInfo != null"
                >
                  Efetuar pagamento
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div>
      <ModalBase
        :id="authCodeModalData.id"
        :title="'Pagamento de conta'"
        :hasFooter="false"
        size="modal-md"
      >
        <div slot="content">
          <AuthCodeModalSection
            :ref="authCodeModalData.id"
            :subSection="authCodeModalData.subSection"
            :email="preset.holder.email"
            :mobile="preset.holder.mobile"
            :type="'bill-payment'"
            lastButtonText="Pagar"
            @code-ready="sendPayment($event)"
            @auth-sent="onAuthSent"
          />
        </div>
      </ModalBase>
    </div>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue,
  requiredIf,
} from 'vuelidate/lib/validators';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import Datepicker from 'vuejs-datepicker';
import SendBillPaymentError from '@/errors/SendBillPaymentError';
import { CurrencyInput } from 'vue-currency-input';
import { Presets } from '@/models/Presets';
import AuthCodeModalSection from '@/components/shared/modal/AuthCodeModalSection';
import BillPaymentValidate from '@/components/financial/billsPayment/BillPaymentValidate';
import ModalBase from '@/components/shared/ModalBase';
import { onlyNumbers } from '@/validations/onlyNumbers';

export default {
  name: 'AppNewBillPayment',
  components: {
    WhiteLabel,
    Datepicker,
    CurrencyInput,
    AuthCodeModalSection,
    BillPaymentValidate,
    ModalBase,
  },
  data() {
    return {
      billInfo: null,
      barCode: '',
      paymentMode: 'others',
      darf:{
        federalRevenueCode: '',
        federalRevenueName: '',
        assessmentPeriod: new Date(),
      },
      fgts: {
        collectionCode:'',
      },
      billPayment: {
        scheduledFor: new Date(),
        partialAmount: null,
        description: null,
      },
      twoFactorDocumentRegex: new RegExp('^[0-9]+$'),
      currencyOptions: {
        currency: 'BRL',
        distractionFree: false,
        autoDecimalMode: true,
      },
      barCodeRegexInput: new RegExp('^[0-9]+$'),
      preset: new Presets(),
      authCodeModalData: {
        id: 'authCodeModal',
        subSection: 'destination',
      },
    };
  },
  async mounted() {
    await this.presets();
  },
  methods: {
    onAuthSent() {
      this.authCodeModalData.subSection = 'code';
    },
    setPaymentMode(paymentMode){
      this.paymentMode = paymentMode;
    },
    setBillInfo(billInfo, barCode){
      this.billInfo = billInfo;
      this.barCode = barCode;
    },
    validateBillInfo() {
      this.$v.$touch();

      if (this.billInfo != null && !this.$v.$invalid) {
        if (this.billInfo.totalUpdated == 0) {
          this.$alert.error(
            'Ops',
            'Não é possível pagar uma conta com valor zerado'
          );

          return;
        }

        if (this.billPayment.partialAmount == 0) {
          this.$alert.error(
            'Ops',
            'Valor para pagamento parcial inválido. Informe um número entre R$ 0,01 e R$ 9.999.999,99'
          );

          return;
        }
        $(`#${this.authCodeModalData.id}`).modal('show');
      }
    },
    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },
    async sendPayment(authCode) {
      var isValid =
        !this.$v.$invalid &&
        this.scheduledForDateGreaterThanOrEqualToToday &&
        this.billInfo &&
        authCode;

      if (isValid) {
        this.$bus.$emit('spinner-run');
        this.authCode = authCode;
        this.billPayment.barCodeNumber = this.barCode;
        if(this.paymentMode == 'fgts')
          this.billPayment.fgts = this.fgts;
        if(this.paymentMode == 'darf')
          this.billPayment.darf = this.darf;

        var paymentAccApi = new PaymentAccountsApi();
        var billPaymentResponse = await paymentAccApi.sendBillPayment(
          this.billPayment,
          this.authCode
        );

        if (billPaymentResponse.status === 200) {
          $(`#${this.authCodeModalData.id}`).modal('hide');

          if (billPaymentResponse.data.status == 'pending') {
            this.$alert.info('Pronto', 'Pagamento agendado com sucesso!');
          } else {
            this.$alert.info('Pronto', 'Pagamento realizado com sucesso!');
          }

          this.$router.push('/pagamentos');
        } else {
          var modelError = new SendBillPaymentError(billPaymentResponse.data);

          this.$alert.error(
            'Ops',
            `Ocorreu um erro ao efetuar pagamento. ${modelError.getMessage()}`
          );

          this.authCodeModalData.subSection = 'destination';
          this.$refs.authCodeModal.reset();
        }

        this.$bus.$emit('spinner-stop');
      }
    },
  },
  computed: {
    scheduledForDateGreaterThanOrEqualToToday() {
      return (
        this.billPayment.scheduledFor >= new Date(new Date().toDateString())
      );
    },
  },
  watch: {
    barCode: function (newValue) {
      var barCodeFormated = newValue.replace(/\s/g, '').replace(/[.]/g, '');
      var match = this.barCodeRegexInput.test(barCodeFormated);

      if (!match) {
        var lastCharacter = newValue.substring(newValue.length - 1);

        this.barCode = newValue.replace(lastCharacter, '');
      } else {
        this.barCode = barCodeFormated;
      }
    },
  },
  validations: {
    billPayment: {
      scheduledFor: {
        required,
      },
      description: {
        required,
      },
      partialAmount: {
        required: requiredIf(function () {
          return this.billInfo != null && this.billInfo.allowPartialPayment;
        }),
        minValue: minValue(0.01),
        maxValue: maxValue(9999999.99),
      },
    },
    darf:{
      federalRevenueCode :{
        required: requiredIf(function(){
          return this.paymentMode == 'darf';
        }),
        exactLength: function(value) {
          if(value == null || value == undefined || value == '') return true;
          return value.length === 4;
        },
        onlyNumbers,
      },
      federalRevenueName :{
        required: requiredIf(function(){
          return this.paymentMode == 'darf';
        }),
      },
      assessmentPeriod :{
        required: requiredIf(function(){
          return this.paymentMode == 'darf';
        }),
      },
    },
    fgts:{
      collectionCode :{
        required: requiredIf(function(){
          return this.paymentMode == 'fgts';
        }),
      },
    },
  },
};
</script>
