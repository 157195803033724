<template>
  <div class="container">
    <div class="row">
      <div class="form-group col-12">
        <WhiteLabel>
          <h4 class="font-weight-light blue">Formato do boleto</h4>
        </WhiteLabel>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="paymentMode"
            id="others"
            value="others"
            @change="changePaymentMode"
            v-model="selectedPaymentMode"
          />
          <label class="form-check-label" for="others"> Outros títulos </label>
        </div>
      </div>
      <div class="col-2">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="paymentMode"
            id="darf"
            value="darf"
            @change="changePaymentMode"
            v-model="selectedPaymentMode"
          />
          <label class="form-check-label" for="darf"> DARF </label>
        </div>
      </div>
      <div class="col-2">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="paymentMode"
            id="fgts"
            value="fgts"
            @change="changePaymentMode"
            v-model="selectedPaymentMode"
          />
          <label class="form-check-label" for="fgts"> FGTS </label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-w-full col-lg-12 mb-4">
        <input
          type="text"
          class="form-control"
          v-model="barCode"
          v-mask="barCodeMask"
          placeholder="Informe a linha digitável"
        />
        <small class="text-danger" v-if="!barCodeIsValid">
          Informe uma linha digitável válida
        </small>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col-2">
        <WhiteLabel class="mx-auto">
          <button
            type="submit"
            class="btn btn-md btn-orange"
            @click.prevent="getBillInfo(barCode, selectedPaymentMode)"
          >
            Validar
          </button>
        </WhiteLabel>
      </div>
    </div>
  </div>
</template>
<script>
import { boleto } from 'boleto-brasileiro-validator';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import Alert from '@/models/Alert';
import BillsPaymentError from '@/errors/BillsPaymentError';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'BillPaymentValidate',
  components: {
    WhiteLabel,
  },
  data: () => ({
    selectedPaymentMode: 'others',
    barCode: '',
    billInfo: null,
  }),
  methods: {
    changePaymentMode() {
      this.$emit('paymentMode', this.selectedPaymentMode);
    },
    async getBillInfo(barCode, paymentMode) {
      if (barCode && this.barCodeIsValid) {
        this.$bus.$emit('spinner-run');

        var paymentAccountApi = new PaymentAccountsApi();
        var result = await paymentAccountApi.getBillInfo(barCode, paymentMode);

        if (result.status !== 200) {
          var alert = new Alert();
          alert.title = 'Ops';
          alert.confirmButtonText = 'Sair';
          const modelError = new BillsPaymentError(result.data);
          const error = modelError.getMessage();

          alert.error = error;
          this.$bus.$emit('spinner-stop');
          this.$bus.$emit('alert-error', alert);
        }

        this.billInfo = result.data;
        if (this.billInfo.dueDate != null) {
          this.billInfo.dueDate = new Date(
            this.billInfo.dueDate
          ).toLocaleDateString('pt-br');
        }

        if (this.billInfo.allowPartialPayment) this.billInfo.partialAmount = 0;

        this.$bus.$emit('spinner-stop');
      } else {
        this.billInfo = null;
      }
      this.$emit('billInfo', this.billInfo, this.barCode);
    },
  },
  computed: {
    barCodeIsValid() {
      let isValid = this.barCode ? boleto(this.barCode) : true;
      this.$emit('barCodeIsValid', isValid);
      return isValid;
    },
    barCodeMaxLength() {
      if (this.barCode == '') return 80;
      else if (this.barCode.charAt(0) == '8') return 48;
      else return 47;
    },
    barCodeMask() {
      if (this.barCode == '')
        return '################################################################################';
      else if (this.barCode.charAt(0) == '8')
        return '################################################';
      else return '###############################################';
    },
  },
};
</script>
