<template>
  <div
    class="content-transference"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="30"
  >
    <div class="top p-4">
      <div class="content-one">
        <div class="col-sm-6">
          <WhiteLabel>
            <router-link
              :to="{ name: 'new-bills-payment' }"
              class="btn btn-orange btn-pill align-self"
            >
              <i class="icon-plus"></i>
              Novo pagamento
            </router-link>
          </WhiteLabel>
        </div>
      </div>
      <div class="content-two">
        <FilterBillsPayment
          @captureBillsPayment="billsPaymentAfterEvent"
          @captureNotFound="notFound"
          :isMobile="false"
          :lengthPage="lengthPage"
          :indexPage="indexPage"
          ref="filterBillsPayment"
        />
      </div>
      <div class="balance-account">
        <div class="left">
          <WhiteLabel>
            <a class="pointer" @click="showBalance = !showBalance">
              <span class="text-uppercase label-16 font mr-1">
                Saldo em conta
              </span>
              <em class="icon-blocked icon-eye large-icons" />
            </a>
          </WhiteLabel>
          <div class="inline">
            <span class="R font">R$</span>
            <span v-show="showBalance" class="value-balance font">{{
              balance.currentBalance
                | currency('', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}</span>
            <span v-show="!showBalance" class="ml-1 value-balance font">
              .....
            </span>
          </div>
        </div>
      </div>
      <div v-if="getCount !== 0">
        <ContentBillsPayment :billsPayment="billsPayment.payments" />
      </div>
      <div v-if="getCount === 0">
        <NotFoundBillsPayment :rangeDate="rangeDateNotFound" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterBillsPayment from '@/components/financial/billsPayment/FilterBillsPayment';
import ContentBillsPayment from '@/components/financial/billsPayment/ContentBillsPayment';
import NotFoundBillsPayment from '@/components/financial/billsPayment/NotFoundBillsPayment';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import Alert from '@/models/Alert';

export default {
  name: 'AppListPayments',
  components: {
    FilterBillsPayment,
    ContentBillsPayment,
    WhiteLabel,
    NotFoundBillsPayment,
  },
  data() {
    return {
      busy: false,
      collapse: false,
      balance: {
        currentBalance: 0,
      },
      rangeDateNotFound: {
        start: new Date(),
        end: new Date(),
      },
      billsPayment: {
        count: 0,
        payments: [],
      },
      indexPage: 0,
      lengthPage: 10,
      showBalance: false,
    };
  },
  async mounted() {
    await this.getBalanceAccount();
  },
  methods: {
    billsPaymentAfterEvent(billsPayment) {
      this.billsPayment = billsPayment;
    },
    notFound(startDate, endDate) {
      this.rangeDateNotFound.end = endDate;
      this.rangeDateNotFound.start = startDate;
    },
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },
    loadMore() {
      if (
        this.billsPayment !== null &&
        this.getCount < this.billsPayment.count
      ) {
        this.$refs.filterBillsPayment.filter.offset.index =
          this.$refs.filterBillsPayment.filter.offset.index + this.lengthPage;
        this.$refs.filterBillsPayment.moreBillsPayment =
          !this.$refs.filterBillsPayment.moreBillsPayment;
      }
    },
  },
  computed: {
    getCount() {
      return this.billsPayment !== null ? this.billsPayment.payments.length : 0;
    },
  },
};
</script>
