<template>
  <div class="mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">Pagamento de Conta</h3>
              <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
                <router-link :to="{ name: 'bills-payment' }">
                  <i class="icon-arrow-left-circle"></i> Voltar
                </router-link>
              </WhiteLabel>
            </div>
            <hr />
            <div class="card-content">
              <div class="form-row col-12">
                <div class="col-12 col-w-full">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">
                      Informações do Beneficiário
                    </h4>
                  </WhiteLabel>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Nome</label>
                  <p>{{ billsPayment.recipientName }}</p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>CPF/CNPJ</label>
                  <p>{{ billsPayment.recipientDocument }}</p>
                </div>
              </div>
              <div class="form-row col-12">
                <div class="col-12 col-w-full">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">
                      Informações do Pagamento
                    </h4>
                  </WhiteLabel>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-12 col-xl-12 mb-4"
                >
                  <label>Linha digitável</label>
                  <p>{{ billsPayment.barCode }}</p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Descrição</label>
                  <p>{{ billsPayment.description }}</p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Protocolo</label>
                  <p>{{ billsPayment.protocolId }}</p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Valor</label>
                  <p>
                    {{
                      billsPayment.value
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Valor pago</label>
                  <p>
                    {{
                      billsPayment.valuePaid
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Juros</label>
                  <p>
                    {{
                      billsPayment.penalty
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Desconto</label>
                  <p>
                    {{
                      billsPayment.discount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </p>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
                >
                  <label>Data e hora da transação</label>
                  <p>
                    {{ billsPayment.createdAt | moment('DD/MM/YYYY HH:mm:ss') }}
                  </p>
                </div>
                <div class="row">
                  <div
                    class="col-12 col-w-full col-md-12 col-lg-12 col-xl-12 mb-4"
                  >
                    <h6 class="font-weight-light text-muted mb-1">Status</h6>
                    <div class="d-flex">
                      <div class="mr-2">
                        <span
                          class="badge badge-pill"
                          :class="statusColor(billsPayment.status)"
                        >
                          <p class="text-uppercase m-0">
                            {{ getStatusPayment(billsPayment.status) }}
                          </p>
                        </span>
                      </div>
                      <p v-if="billsPayment.status === 'paid'">
                        {{
                          billsPayment.paymentDate
                            | moment('DD/MM/YYYY HH:mm:ss')
                        }}
                      </p>
                      <p v-if="billsPayment.status === 'canceled'">
                        {{
                          billsPayment.canceledDate
                            | moment('DD/MM/YYYY HH:mm:ss')
                        }}
                      </p>
                      <p v-if="billsPayment.status === 'reproved'">
                        {{
                          billsPayment.rejectedDate
                            | moment('DD/MM/YYYY HH:mm:ss')
                        }}
                      </p>
                      <p v-if="billsPayment.status === 'chargeback'">
                        {{
                          billsPayment.chargebackAt
                            | moment('DD/MM/YYYY HH:mm:ss')
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillsPayment from '@/models/BillsPayment';

export default {
  name: 'AppDetailsBillsPayment',
  data() {
    return {
      billsPayment: new BillsPayment(),
    };
  },
  mounted() {
    this.billsPayment = this.$route.params.payment;
  },
  methods: {
    getStatusPayment(type) {
      switch (type) {
        case 'paid':
          return 'PAGO';
        case 'pending':
          return 'EM ABERTO';
        case 'canceled':
          return 'CANCELADO';
        case 'reproved':
          return 'REPROVADO';
        case 'chargeback':
          return 'ESTORNADO';
        default:
          break;
      }
    },
    statusColor(type) {
      return {
        'badge-success': type === 'paid' || type === 'chargeback',
        'badge-reproved': type === 'reproved',
        'badge-pending': type === 'pending',
        'badge-canceled': type === 'canceled',
      };
    },
  },
};
</script>
